<template>
    <div>
        <BackNavigatorBar/>
        <div class="filter-panel">
            <CSSelect>
                <el-date-picker
                    v-model="queryParams.date"
                    type="month"
                    format="yyyy-MM"
                    value-format="yyyy-MM"
                    placeholder="选择月份"
                    prefix-icon="el-icon-time"
                    :editable="false"
                >
                </el-date-picker>
            </CSSelect>
            <input type="text" class="cs-input" placeholder="搜索员工姓名" v-model="queryParams.staffName">
            <button class="btn btn-search btn-primary" @click="searchAttendanceSchedules"
                    style="width: 50px;padding: 0;">查询
            </button>
        </div>

        <div class="result-panel">
            <div v-if="timesGroupList.length > 0" style="padding: 20px 20px 0 20px;font-size: 14px;color: #000; margin-left: 10px">
        <span v-for="times in timesGroupList" :key="times.id">
          {{ times.name }} {{ times.times.join('  ') }}
        </span>
            </div>
            <div style="overflow: hidden;padding: 10px 20px;box-sizing: border-box;">
                <div style="overflow-x: auto;padding: 10px;box-sizing: border-box;">
                    <div style="display: inline-block;">
                        <table class="table modal-inner-table" style="--modal-inner-table-font-size:14px;">
                            <thead>
                            <tr
                                v-html="getThead(checkedQueryMonth)"
                            ></tr>
                            </thead>
                            <tbody>
                            <template v-if="rostersList.length > 0">
                                <tr v-for="item in rostersList"
                                    :key="item.id"
                                >
                                    <td>{{ DEPARTMENT_TYPE_STR[dutyType] }}</td>
                                    <td>{{ item.userName }}</td>
                                    <td v-for="n in currentMonthDays"
                                        :key="n"
                                    >
                                        <template
                                            v-if="item.schedule[checkedQueryMonth+'-'+ n.toString().padStart(2, '0')]">
                                            <template
                                                v-if="item.schedule[checkedQueryMonth+'-'+ n.toString().padStart(2, '0')].isWork === 0">
                                                休
                                            </template>
                                            <template v-else>
                                                {{ substringTimesName(item.schedule[checkedQueryMonth + '-' + n.toString().padStart(2, '0')].name) }}
                                            </template>
                                        </template>
                                        <template v-else>
                                            -
                                        </template>
                                    </td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BackNavigatorBar from "@/components/common/BackNavigatorBar";
import CSSelect from "@/components/common/CSSelect";
import dayjs from "dayjs";
import {queryRosterArrangementUrl} from "@/requestUrl";
import {DEPARTMENT_TYPE_STR} from "@/constant";

export default {
    name: "AttendanceScheduleDetail",
    props: {
        id: Number,
    },
    components: {
        BackNavigatorBar,
        CSSelect
    },
    data() {
        return {
            DEPARTMENT_TYPE_STR,
            queryParams: {
                date: dayjs().format('YYYY-MM'),
                staffName: '',
            },
            currentMonthDays: '',
            timesGroupList: [],
            rostersList: [],
            dutyType: '',
            theadHtml: '',
            checkedQueryMonth: dayjs().format('YYYY-MM'),
        }
    },
    mounted() {
        this.searchAttendanceSchedules();
    },
    methods: {
        substringTimesName(name) {
            try {
                return name.substring(0, 1);
            } catch (err) {
                console.log(err, '-----', name)
                return '-';
            }
        },
        // 获取表头
        getThead(date) {
            const isLeapYear = Number(date.slice(0, 4)) % 4 === 0;
            let dayArr = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
            if (isLeapYear) {
                dayArr[1] = 29;
            }
            this.currentMonthDays = dayArr[Number(date.slice(5)) - 1];
            const weekMapping = {
                0: "日",
                1: "一",
                2: "二",
                3: "三",
                4: "四",
                5: "五",
                6: "六",
            };
            let tr = '<th style="min-width: 70px;">部门</th><th style="min-width: 70px;">员工</th>';
            for (let i = 1; i <= dayArr[Number(date.slice(5)) - 1]; i++) {
                tr += `<th>
                            <div>${
                    weekMapping[
                        dayjs(
                            date.concat(this.$vc.prefixZero(i))
                        ).day()
                        ]
                }</div>
                            <div>${this.$vc.prefixZero(i)}</div>
                        </th>`;
            }
            return tr;
        },
        // 搜索考勤
        async searchAttendanceSchedules() {
            const [timesGroups, rosters] = await this.getAttendanceSchedules();
            this.timesGroupList = timesGroups;
            this.rostersList = rosters.filter(item => {
                const schedule = {};
                item.arrangements.forEach(arrangements => {
                    schedule[arrangements.date] = arrangements;
                })
                item.schedule = schedule;
                return item.userName.includes(this.queryParams.staffName);
            })
        },
        // 获取考勤
        getAttendanceSchedules() {
            this.checkedQueryMonth = this.queryParams.date;
            return this.$fly.post(queryRosterArrangementUrl, {
                regionCode: this.$vc.getCurrentRegion().code,
                id: this.id,
                date: this.queryParams.date,
            })
                .then(res => {
                    if (res.code !== 0) {
                        return [[], []];
                    }
                    this.dutyType = res.data.dutyType;
                    return [res.data.timeGroups, res.data.rosters];
                })
        }
    }
}
</script>

<style lang="stylus" scoped>
.table.modal-inner-table
    --modal-inner-table-font-size 14px
    --modal-inner-cell-min-width 50px

    td, th
        min-height 69px
        position relative

        div
            min-width 40px
</style>
